const axios = require('axios')

export default async function sendShoppingCart(
  values: Record<string, unknown>
): Promise<any> {
  const response = await axios.post(
    process.env.GATSBY_SHOPPINGCART_SERVICE_URL + '/api/shoppingcard',
    values
  )
  return response
}
