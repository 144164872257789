import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@objects/container'
import Headline from '@objects/headline'
import { FormattedMessage, useIntl } from 'react-intl'
import BreadCrumbs from '@objects/breadcrumb'
import OrderForm from '@components/forms/orderform'

const useStyles = makeStyles((theme) => ({
  headlineWrapper: {
    paddingTop: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(20),
    },
  },
}))

export default function RootIndex(): ReactElement {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <section>
      <Container background={'light'} type="nomargin">
        <BreadCrumbs
          crumbList={[
            {
              label: intl.formatMessage({
                id: 'navigation.breadcrumb.current.label',
              }),
              link: intl.formatMessage({
                id: 'navigation.breadcrumb.current.url',
              }),
            },
          ]}
        />
      </Container>

      <Container className={classes.headlineWrapper} width={'sm'}>
        <Headline level={1}>
          <FormattedMessage id={'basket.checkout.headline'} />
        </Headline>
      </Container>
      <Container width={'sm'} background={'light'}>
        <OrderForm />
      </Container>
    </section>
  )
}
